<template>
  <v-container class="pa-0">
    <v-row
      no-gutters
      class="ma-0 pa-0"
    >
      <!-- 모바일 화면 에서는 상단 인포가 안보이게 처리 -->
      <v-col
        cols="12"
        class="d-none d-sm-flex"
      >
        <v-alert
          border="bottom"
          color="info"
          outlined
          dense
          class="ma-0 "
        >
          <div class="text-body-2">
            <v-icon small color="info">info</v-icon>
            카테고리의 삭제는 <span class="error--text font-weight-bold">일괄합체</span>를 활용해 주십시오.
          </div>
          <div class="text-body-2">
            <v-icon small color="info">info</v-icon>
            수정시에는 해당 카테고리와 <span class="primary--text font-weight-bold">연계된 상위 정보</span>도 <span class="error--text font-weight-bold">동반수정</span>됩니다.
          </div>
          <div class="text-body-2">
            <v-icon small color="info">info</v-icon>
            순서의 변경은 해당 카테고리를 잡아서 이동시킨 후 <span class="primary--text font-weight-bold">순서변경</span> 버튼을 클릭하셔야 반영됩니다.
          </div>
        </v-alert>
        <!-- <v-divider></v-divider> -->
      </v-col>

      <v-col cols="12" class="pa-0 mt-1">

        <!-- 좌우로 분리되는 내용 시작 space-between -->
        <v-row
          no-gutters
          justify="space-between"
          class="ma-0 pa-0"
        >
          <!-- 좌측 내용(상위 카테고리) 시작 -->
          <v-col cols="6" class="pr-1">
            <v-toolbar dense flat class="pa-0 ma-0">
              <v-btn :disabled="!orderChanged" text color="primary" @click="orderChange" class="pa-1 mr-2">
                <v-icon small left class="mr-0">mdi-cached</v-icon> 순서변경
              </v-btn>
              <v-btn text :color="allChecked ? 'warning' : 'success'" @click="allCheck" class="pa-1 mr-2">
                <v-icon small left class="mr-0">
                  {{ allChecked ? 'mdi-checkbox-multiple-blank-outline' : 'mdi-check-box-multiple-outline' }}
                </v-icon> {{ allChecked ? '전체해제' : '전체선택' }}
              </v-btn>
              <v-btn  text color="indigo" @click="mergeCate" class="pa-1">
                <v-icon small left class="mr-0">mdi-collapse-all</v-icon> 일괄합체
              </v-btn>
              <!-- <v-btn text color="error" @click="allRemove" class="pa-1 mr-2">
                <v-icon small left class="mr-0">mdi-delete-forever-outline</v-icon> 일괄삭제
              </v-btn> -->
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>

            <div class="ma-3"></div>

            <v-card
              tile
              elevation="0"
              min-height="200"
            >
              <draggable
                :list="cates"
                :disabled="!dndEnabled"
                @start="dragging = true"
                @end="dragging = false"
                @update="setOrderChage"
              >
                <v-row
                  v-for="(cate, i) in cates"
                  :key="cate.id"
                >
                  <v-hover v-slot:default="{ hover }" transition="fade-transition">
                    <v-col class="my-2 ml-2 mr-12 pl-5 pr-10 py-0">
                      <v-chip
                        v-if="!cate.isEdit"
                        :color="(hover || cate.isChecked) ? 'light-blue lighten-5' : 'white'"
                        class="ml-1"
                      >
                        <v-icon
                          small
                          left
                          class="mr-2"
                          @click="cate.isChecked = !cate.isChecked"
                        >{{ cate.isChecked ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}</v-icon>
                        <span
                          @click="viewSubCate(cate, i)"
                          :class="cate.isClicked ? 'warning--text font-weight-bold' : 'black--text font-weight-regular'"
                          style="cursor: pointer"
                        >{{ cate.gubun1 }}</span>
                        <v-icon
                          v-show="hover"
                          small
                          right
                          @click="setEdit(cate, i)"
                          class="ml-5"
                        >edit</v-icon>
                        <!-- <v-icon
                          v-show="hover"
                          small
                          right
                          @click="remove(cate, i)"
                          class="ml-3"
                        >mdi-trash-can-outline</v-icon> -->
                        <v-icon
                          v-show="cate.isClicked"
                          right
                          color="warning"
                          class="ml-10"
                        >mdi-arrow-right-circle</v-icon>
                      </v-chip>
                      <v-text-field
                        v-else
                        v-model.trim="edit.gubun1"
                        :rules="[rules.required, rules.counter, rules.chkObverlab]"
                        label=""
                        placeholder="입력하세요(최대 7자)"
                        counter="7"
                        maxlength="7"
                        append-icon="mdi-close-circle-outline"
                        @click:append="cate.isEdit = false; dndEnabled = true"
                        @keydown.esc="cate.isEdit = false; dndEnabled = true"
                        append-outer-icon="done"
                        @click:append-outer="editCate(cate, i)"
                        @keypress.enter="editCate(cate, i)"
                        @keypress="rmSpKeys"
                        @keyup="edit.gubun1 = rmSpChars(edit.gubun1)"
                      ></v-text-field>
                    </v-col>
                  </v-hover>
                </v-row>
              </draggable>

              <!-- 입력 텍스트필드 -->
              <v-row>
                <v-col class="mt-5 ml-3 mr-12 pl-5 pr-10">
                  <v-text-field
                    ref="txtAddCate"
                    v-model.trim="form.gubun1"
                    :rules="[rules.required, rules.counter, rules.chkObverlab]"
                    label="등록"
                    placeholder="입력하세요(최대 7자)"
                    counter="7"
                    maxlength="7"
                    append-icon="mdi-close-circle-outline"
                    @click:append="init"
                    @keydown.esc="init"
                    append-outer-icon="done"
                    @click:append-outer="agree()"
                    @keypress.enter="agree()"
                    @keypress="rmSpKeys"
                    @keyup="form.gubun1 = rmSpChars(form.gubun1)"
                    @blur="$refs.txtAddCate.resetValidation()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <!-- 가운데 -->
          <v-divider v-show="viewSub" vertical></v-divider>

          <!-- 우측 내용(하위카테고리) 시작 -->
          <v-col v-show="viewSub" class="pl-1">
            <v-toolbar dense flat class="pa-0 ma-0">
              <v-btn :disabled="!subOrderChanged" text color="primary" @click="orderChangeSub" class="pa-1 mr-2">
                <v-icon small left class="mr-0">mdi-cached</v-icon> 순서변경
              </v-btn>
              <v-btn text :color="subAllChecked ? 'warning' : 'success'" @click="allCheckSub" class="pa-1 mr-2">
                <v-icon small left class="mr-0">
                  {{ subAllChecked ? 'mdi-checkbox-multiple-blank-outline' : 'mdi-check-box-multiple-outline' }}
                </v-icon> {{ subAllChecked ? '전체해제' : '전체선택' }}
              </v-btn>
              <v-btn  text color="indigo" @click="mergeCateSub" class="pa-1">
                <v-icon small left class="mr-0">mdi-collapse-all</v-icon> 일괄합체
              </v-btn>
              <!-- <v-btn text color="error" @click="allRemoveSub" class="pa-1 mr-2">
                <v-icon small left class="mr-0">mdi-delete-forever-outline</v-icon> 일괄삭제
              </v-btn> -->
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>

            <div v-show="viewTitle"
            class="text-subtitle-2 ma-3 grey--text text--darken-1">
              <v-icon small color="warning" class="mx-1">mdi-arrow-right-circle</v-icon>
              <strong class="primary--text">{{ viewTitle }}</strong>의 하위카테고리
            </div>

            <draggable
              :list="subCates"
              :disabled="!subDndEnabled"
              @start="subDragging = true"
              @end="subDragging = false"
              @update="setOrderChageSub"
            >
              <v-row
                v-for="(sc, i) in subCates"
                :key="i"
              >
                <v-hover v-slot:default="{ hover }" transition="fade-transition">
                  <v-col class="my-2 ml-2 mr-12 pl-5 pr-10 py-0">
                    <v-chip
                      v-if="!sc.isEdit"
                      :color="(hover || sc.isChecked) ? 'light-blue lighten-5' : 'white'"
                      class="ml-1"
                    >
                      <v-icon
                        small
                        left
                        class="mr-2"
                        @click="sc.isChecked = !sc.isChecked"
                      >{{ sc.isChecked ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}</v-icon>
                      {{ sc.title }}
                      <v-icon
                        v-show="hover"
                        small
                        right
                        @click="setSubEdit(sc, i)"
                        class="ml-5"
                      >edit</v-icon>
                      <!-- <v-icon
                        v-show="hover"
                        small
                        right
                        @click="removeSub(sc, i)"
                        class="ml-3"
                      >mdi-trash-can-outline</v-icon> -->
                    </v-chip>
                    <v-text-field
                      v-else
                      v-model.trim="subEdit.title"
                      :rules="[rules.required, rules.counter10, rules.chkOverlabSub]"
                      label=""
                      :placeholder="typeNum === 3 ? '입력하세요(최대 10자)' : '입력하세요(최대 7자)'"
                      :counter="typeNum === 3 ? 10 : 7"
                      :maxlength="typeNum === 3 ? 10 : 7"
                      append-icon="mdi-close-circle-outline"
                      @click:append="sc.isEdit = false; subDndEnabled = true"
                      @keydown.esc="sc.isEdit = false; subDndEnabled = true"
                      append-outer-icon="done"
                      @click:append-outer="editSubCate(sc, i)"
                      @keypress.enter="editSubCate(sc, i)"
                      @keypress="rmSpKeys"
                      @keyup="subEdit.title = rmSpChars(subEdit.title)"
                    ></v-text-field>
                  </v-col>
                </v-hover>
              </v-row>
            </draggable>

            <!-- !! 서브 입력 텍스트필드 -->
            <v-row>
              <v-col id="subInpArea" class="mt-5 ml-3 mr-12 pl-5 pr-10">
                <v-text-field
                  ref="txtSubAddCate"
                  v-model.trim="subForm.title"
                  :rules="[rules.required, rules.counter10, rules.chkOverlabSub]"
                  label="등록"
                  :placeholder="typeNum === 3 ? '입력하세요(최대 10자)' : '입력하세요(최대 7자)'"
                  :counter="typeNum === 3 ? 10 : 7"
                  :maxlength="typeNum === 3 ? 10 : 7"
                  append-icon="mdi-close-circle-outline"
                  @click:append="init"
                  @keydown.esc="init"
                  append-outer-icon="done"
                  @click:append-outer="subAgree"
                  @keypress.enter="subAgree"
                  @keypress="rmSpKeys"
                  @keyup="subForm.title = rmSpChars(subForm.title)"
                  @blur="$refs.txtSubAddCate.resetValidation()"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

      </v-col>
    </v-row>

    <merge-cate02-child ref="mergeCate02Child"></merge-cate02-child>
    <merge-cate02-parent ref="mergeCate02Parent"></merge-cate02-parent>

  </v-container>
</template>

<script>
import draggable from 'vuedraggable'
import { rmSpKeys, rmSpChars } from '@/lib/keyEvents'
import mergeCate02Child from '@/components/admin/MergeCate02Child'
import mergeCate02Parent from '@/components/admin/MergeCate02Parent'

export default {
  components: {
    draggable,
    mergeCate02Child,
    mergeCate02Parent
  },

  data: () => ({
    typeNum: 0, // !! 타입번호
    cates: [],
    subCates: [],
    selectedCates: [], // !! 선택한 부모 카테고리
    selectedSubCates: [], // !! 선택한 자식 카테고리
    // !! 부모
    form: {
      id: 0,
      num: 0,
      type1: 0,
      gubun1: '',
      isEdit: false,
      isChecked: false,
      sub: ''
    },
    // 수정폼
    edit: {
      id: 0,
      num: 0,
      type1: 0,
      gubun1: '',
      isEdit: false,
      isChecked: false,
      sub: ''
    },
    oldIndex: -1, // 기존 선택한 카테고리
    dndEnabled: true, // dnd 사용여부
    dragging: false, // dnd 중
    allChecked: false, // 전체선택
    orderChanged: false, // 부모의 순서변경여부
    // !! 자식 등록폼
    subForm: {
      title: '',
      isEdit: false,
      isChecked: false
    },
    subEdit: {
      title: '',
      isEdit: false,
      isChecked: false
    },
    subOldIndex: -1, // 기존 선택한 카테고리
    subDndEnabled: true, // dnd 사용여부
    subDragging: false, // dnd 중
    subAllChecked: false, // 전체선택
    subOrderChanged: false, // 자식의 순서변경여부
    // !! 기타
    viewSub: false, // 하위 카테고리 영역 보일지 여부
    viewTitle: '', // 자식의 상단 타이틀
    selectedCateIndex: 0 // 선택한 부모 카테고리 인덱스(얘는 0부터)
  }),

  computed: {
    // data 에서는 this를 쓸 수 없으므로 computed 에서 룰을 만든다
    rules () {
      return {
        required: value => !!value || '입력값은 필수 정보입니다',
        counter: value => value.length <= 7 || '입력값은 최대 7글자이어야 합니다',
        counter10: value => value.length <= 10 || '입력값은 최대 10글자이어야 합니다',
        chkObverlab: value => {
          const gubuns = this.cates.map(s => s.gubun1)
          if (gubuns.includes(value)) return '이미 등록된 카테고리입니다'
          else return true
        },
        // 자식중복체크
        chkOverlabSub: value => {
          const titles = this.subCates.map(s => s.title)
          if (titles.includes(value)) return '이미 등록된 카테고리입니다'
          else return true
        }
      }
    }
  },

  watch: {
    '$route' (to, from) {
      // this.$router.push(to.path)

      // 중요: 넘어온 타입 번호를 매칭시켜야 한다!
      this.typeNum = parseInt(this.$route.params.type, 10)
      // 리스트 데이터 패칭
      this.getDataFromApi()
        .then(({ items }) => {
          if (items && items.length > 0) { // 데이터가 있다면..
            this.cates = items // 데이터를 상위카테고리에 매칭 시키고
            this.viewSubCate(this.cates[0], 0) // 첫번째 상위카테고리의 하위 카테고리를 만든다.
          } else {
            this.cates = []
          }
          // this.goToInput() // 스크롤을 내린다
        })

      // 폼 초기화
      this.init()
    }
  },

  mounted () {
    if (!this.$store.state.ui.dbcode) {
      this.redirect('/')
    } else if (!(this.$store.state.ui.lv === 'M' || this.$store.state.ui.lv === 'G')) {
      // 참고: router.js 에서 이미 체크했지만.. 관리(대행)자가 아니면 .. 기본화면으로 돌려보낸다
      this.redirect('/case')
    }

    // 중요: 넘어온 타입 번호를 매칭시켜야 한다!
    this.typeNum = parseInt(this.$route.params.type, 10)
    // 리스트 데이터 패칭
    this.getDataFromApi()
      .then(({ items }) => {
        if (items && items.length > 0) { // 데이터가 있다면..
          this.cates = items // 데이터를 상위카테고리에 매칭 시키고
          this.viewSubCate(this.cates[0], 0) // 첫번째 상위카테고리의 하위 카테고리를 만든다.
        } else {
          this.cates = []
        }
        // this.goToInput() // 스크롤을 내린다
      })

    // 폼 초기화
    this.init()
  },

  methods: {
    // 중요: 공통함수 --
    rmSpKeys, // keypress 특수문자 입력 막기
    rmSpChars, // keyup, down 특수문자 제거
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    redirect (to = '') {
      this.$router.push(to)
    },
    // 참고: 초기화 함수
    init () {
      // 등록폼 validation 초기화
      this.$refs.txtAddCate.resetValidation()
      // 자식 등록폼 validation 초기화
      this.$refs.txtSubAddCate.resetValidation()

      // !! 부모
      this.form.id = 0
      this.form.num = 0
      this.form.type1 = this.typeNum // 타입
      this.form.gubun1 = ''
      this.form.isEdit = false
      this.form.isChecked = false
      this.form.sub = ''

      this.edit.id = 0
      this.edit.num = 0
      this.edit.type1 = this.typeNum // 타입
      this.edit.gubun1 = ''
      this.edit.isEdit = false
      this.edit.isChecked = false
      this.edit.sub = ''

      this.oldIndex = 0 // 기존 선택요소를 초기화
      this.dndEnabled = true // 드래그 앤 드랍 사용가능하게
      this.orderChanged = false // 순서변경여부 초기화
      this.dragging = false // dnd 중
      this.allChecked = false // 전체선택

      // !! 자식
      // this.subCates = [] // 서브카테고리 초기화.. 하면 안되네!
      // this.viewTitle = '' // 얘도 하면 안되네
      this.subForm.title = ''
      this.subForm.isEdit = false
      this.subForm.isChecked = false

      this.subEdit.title = ''
      this.subEdit.isEdit = false
      this.subEdit.isChecked = false

      this.subOldIndex = 0 // 자식: 기존 선택요소를 초기화
      this.subDndEnabled = true // 자식: dnd 사용여부
      this.subOrderChanged = false // 자식 순서변경여부 초기화
      this.subDragging = false // 자식: dnd 중
      this.subAllChecked = false // 자식: 전체선택

      this.selectedCates = [] // !! 선택한 부모 카테고리 초기화
      this.selectedSubCates = [] // !! 선택한 자식 카테고리 초기화
    },
    // 리스트 리프레시 함수
    async getList () {
      try {
        const { items } = await this.getDataFromApi()
        // 데이터가 있다면...
        if (items && items.length > 0) {
          // 데이터를 상위카테고리에 매칭 시키고
          this.cates = items
          return items.length
        } else {
          this.cates = []
          return 0
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 데이터 패칭
    async getDataFromApi () {
      try {
        // DB에서 리스트 가져오기 - typeNum 에 해당하는 넘을 가져와야 한다!
        const { data } = await this.$axios.get(`admin/cate/getType/${this.typeNum}`)
        if (!data.success) throw new Error(`list error: ${data.message}`)
        return { items: data.cates }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 중요: 서브 카테고리를 보여주는 함수
    async viewSubCate (cate, index) {
      try {
        this.subCates = [] // 서브카테고리 초기화
        this.viewSub = false // 자식리스트 안보이게
        this.selectedCateIndex = index // !! 현재 선택한 부모카테고리 인덱스

        // !! 안전장치: 상위 카테고리가 있어야 하위 영역을 보이게 만든다.
        if (this.cates && this.cates.length > 0) {
          // this.cates 의 끝에 isClicked 를 만들어 넣는다.
          this.cates.forEach(c => { c.isClicked = false })

          // 선택한 상위카테고리의 하위카테고리만 보여준다.
          await this.setSubCate(cate)

          this.viewSub = true
          this.viewTitle = cate.gubun1
        } else {
          this.viewSub = false
          this.viewTitle = ''
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 선택한 상위카테고리의 서브카테고리를 세팅한다.
    setSubCate (cate) {
      try {
        cate.isClicked = true // 넘어온 넘만 클릭한것으로 만든다.
        this.subOrderChanged = false // 자식의 순서변경을 무효화 시킨다.

        // 서브카테고리가 있는 경우 자식카테고리 리스트를 만든다
        if (cate.sub && cate.sub.length > 0) {
          // 서브카테고리를 만든다
          const subs = cate.sub.split('|')
          for (let i = 0; i < subs.length; i++) {
            this.subCates.push({ title: subs[i], isEdit: false, isChecked: false })
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 중요: 부모 -----
    // 부모 카테고리 등록
    async agree () {
      try {
        // 최종 입력값 검증
        if (!this.$refs.txtAddCate.validate()) throw new Error('입력값을 확인해 주세요')
        // 입력값 비어있는지 체크
        if (!this.form.gubun1) throw new Error('입력값을 확인해 주세요')
        // 중복체크
        if (!this.chkObverlab(this.form.gubun1)) throw new Error('이미 등록된 카테고리입니다')

        // num 의 가장 큰값을 만들어준다.
        let maxNum = 0
        if (this.cates.length > 0) {
          maxNum = Math.max(...(this.cates.map(s => s.num)))
        }
        this.form.num = maxNum + 1
        this.form.type1 = this.typeNum // !! 타입을 다시 맞춰주는게 중요!

        // DB 처리
        const { data } = await this.$axios.post('admin/cate/addCate', this.form)
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
        // refresh
        const dataLength = await this.getList()
        if (dataLength > 0) {
          this.init() // 초기화 하고
          this.viewSub = true // 하위 카테고리 보이게한다
          // 리스트 리프레시 하고 방금 등록한 넘을 선택하자
          const index = this.cates.length - 1
          this.viewSubCate(this.cates[index], index)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // edit 선택
    async setEdit (cate, index) {
      if (this.edit.gubun1) { // 기존 선택한게 있으면 초기화
        this.cates[this.oldIndex].isEdit = false
      }

      this.oldIndex = index
      cate.isEdit = true
      this.edit.gubun1 = cate.gubun1
      this.edit.id = cate.id
      this.edit.num = cate.num
      this.edit.isEdit = true // !! 편집중으로 표시
      this.edit.isChecked = cate.isChecked

      this.dndEnabled = false // 선택중엔 드래그앤 드랍 금지
    },
    // 선택편집
    async editCate (cate, index) {
      try {
        // 입력값 비어있는지 체크
        if (!this.edit.gubun1) throw new Error('입력값을 확인해 주세요')
        // 중복체크
        if (!this.chkObverlab(this.edit.gubun1)) throw new Error('이미 등록된 카테고리입니다')

        const oldValue = this.cates[index].gubun1 // 변경전 값
        const newValue = this.edit.gubun1 // 변경될 값

        this.edit.type1 = this.typeNum // !! 타입을 다시 맞춰주는게 중요!

        // !! 편집하는 카테고리의 num,sub 을 edit 폼과 맞춰야 한다!
        this.edit.num = cate.num
        this.edit.sub = cate.sub

        // 상위(부모)카테고리 수정임을 확실히 보낸다.
        this.edit.changeType = 1
        // 변경되기전의 값과 변경될 값을 보내기위해 붙인다.
        this.edit.oldValue = oldValue
        this.edit.newValue = newValue

        // DB 처리
        const { data } = await this.$axios.post('admin/cate/cateUpdate', this.edit)
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
        // refresh
        await this.getList()
        this.init() // 초기화 하고
        cate.isEdit = false // 편집창 닫고
        this.viewSubCate(this.cates[index], index) // 편집한 카테고리의 서브 타이틀 변경
        this.dndEnabled = true // 편집 끝나면 다시 드래그 가능하게 한다
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 중복체크 함수
    chkObverlab (value) {
      if (this.cates || this.cates.length > 0) {
        const gubuns = this.cates.map(s => s.gubun1)
        if (gubuns.includes(value)) {
          return false
        } else {
          return true
        }
      }
    },
    // 리스트 순서를 변경하도록 하는 메서드
    async orderChange () {
      try {
        if (this.orderChanged) { // 순서가 변경된 경우만..
          this.orderChanged = false // 순서변경 여부 초기화

          const ids = this.cates.map(s => s.id) // id 배열을 따로 만든다.
          // DB 처리
          const { data } = await this.$axios.post('admin/cate/changeOrder', { ids })
          if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
          // refresh
          await this.getList()
          this.init() // 초기화 하고
          this.viewSubCate(this.cates[this.selectedCateIndex], this.selectedCateIndex) // 선택한 카테고리에 포커스를 준다
        } else {
          throw new Error('카테고리 순서를 먼저 변경해 주세요')
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 중요: 부모의 순서변경 이벤트시
    setOrderChage (e) {
      this.orderChanged = true
      // !! 순서가 변경되면 현재 선택한 부모의 인덱스가 변경되므로 찾아줘야 한다.
      // 클릭한 것 = 선택한 것 = isClicked === true 다
      this.selectedCateIndex = this.cates.findIndex(s => s.isClicked === true)
    },
    // 리스트 체크박스 전체 선택/해제
    allCheck () {
      if (this.cates.length > 0) {
        if (!this.allChecked) {
          this.cates.forEach(c => { c.isChecked = true })
          this.allChecked = true
        } else {
          this.cates.forEach(c => { c.isChecked = false })
          this.allChecked = false
        }
      }
    },
    // 중요: 일괄합체 - 부모
    async mergeCate () {
      try {
        // 체크된 갯수가 2개 이상이어야 의미가 있다
        if (this.checkedLength() < 2) {
          throw new Error(`합체할 2개 이상의 카테고리를 선택하시기 바랍니다`)
        }

        // this.typeNum - 1 이 1이면 소송 / 2면 자문이다
        const cType = this.typeNum - 1

        // 합체대상으로 선택된 카테고리의 아이디가 리턴된다.
        const mId = await this.$refs.mergeCate02Parent.open('카테고리', { width: 550 }, this.selectedCates, cType)
        if (mId) {
          // refresh
          this.getDataFromApi()
            .then(({ items }) => {
              if (items && items.length > 0) { // 데이터가 있다면..
                this.cates = items // 데이터를 상위카테고리에 매칭 시키고

                // !! 넘어온 아이디로 선택할 넘의 this.cates 배열내 인덱스를 찾는다
                const index = this.cates.findIndex(c => c.id === mId)

                this.$nextTick(function () {
                  this.viewSubCate(this.cates[index], index)
                })
              }
            })
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 부모 - 체크된 갯수 리턴 & this.selectedCates 배열에 선택한 넘을 매칭
    checkedLength () {
      this.selectedCates = [] // 초기화
      let cnt = 0
      this.cates.forEach(c => {
        if (c.isChecked) {
          this.selectedCates.push(c) // 선택한 카테고리 배열에 넣어준다.
          cnt++
        }
      })
      return cnt
    },
    // 부모 선택삭제
    // async remove (cate, index) {
    //   try {
    //     const msg = `삭제시에는 해당 카테고리와 연계된 상위 정보도 동반삭제되므로 신중을 기하십시오.`
    //     if (await this.$refs.cd0923.open('삭제', msg, { color: 'error', width: 380 })) {
    //       // 부모 카테고리 삭제
    //       this.cates.splice(index, 1)
    //       // 선택한 넘과 넘어온 넘의 인덱스가 같으면 자식창의 카테고리도 모두 지워야 한다.
    //       if (this.selectedCateIndex === index) {
    //         // 초기화
    //         this.subCates = []
    //         this.viewTitle = ''
    //         // 남아있는 카테고리가 있으면 첫번째 것을 매칭해서 보여줘야 한다.
    //         if (this.cates.length > 0) {
    //           this.viewSubCate(this.cates[0], 0)
    //         } else {
    //           // 남아있는 카테고리가 없으면 자식창을 보여주지 말자
    //           this.viewSub = false
    //         }
    //       }
    //       // 넘어온 cate.id 를 넘겨서 지워주면 된다.
    //       const { data } = await this.$axios.post('admin/cate/deleteCate', { selectedIds: cate.id })
    //       if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
    //       // refresh
    //       await this.getDataFromApi()
    //       this.init()
    //     }
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // },
    // 일괄삭제
    // async allRemove () {
    //   try {
    //     let selectedIds = [] // 선택한 아이디만
    //     for (let i = 0; i < this.cates.length; i++) {
    //       if (this.cates[i].isChecked) {
    //         selectedIds.push(this.cates[i].id)
    //       }
    //     }
    //     if (selectedIds.length <= 0) throw new Error('삭제할 카테고리를 선택하십시오')
    //     const msg = `삭제시에는 해당 카테고리와 연계된 상위 정보도 동반삭제되므로 신중을 기하십시오.`
    //     if (await this.$refs.cd0923.open('삭제', msg, { color: 'error', width: 380 })) {
    //       // 선택한 카테고리를 순회하면서 지워준다
    //       for (let i = 0; i < selectedIds.length; i++) {
    //         // id로 인덱스를 찾고 지운다
    //         const index = this.cates.findIndex(c => c.id === selectedIds[i])
    //         this.cates.splice(index, 1)
    //         // 선택한 넘의 인덱스면 자식창의 카테고리도 모두 지워야 한다.
    //         if (this.selectedCateIndex === index) {
    //           // 초기화
    //           this.subCates = []
    //           this.viewTitle = ''
    //         }
    //       }
    //       // 남아있는 카테고리가 있으면 첫번째 것을 매칭해서 보여줘야 한다.
    //       if (this.cates.length > 0) {
    //         this.viewSubCate(this.cates[0], 0)
    //       } else {
    //         // 남아있는 카테고리가 없으면 자식창을 보여주지 말자
    //         this.viewSub = false
    //       }
    //       // selectedIds 배열을 넘겨서 지우면 된다.
    //       const { data } = await this.$axios.post('admin/cate/deleteCate', { selectedIds })
    //       if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
    //       // refresh
    //       await this.getDataFromApi()
    //       this.init()
    //     }
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // },
    // 중요: 자식 처리 --------
    // 자식 카테고리 등록
    async subAgree () {
      try {
        // 최종 입력값 검증
        if (!this.$refs.txtSubAddCate.validate()) throw new Error('입력값을 확인해 주세요')
        // 입력값 비어있는지 체크
        if (!this.subForm.title) throw new Error('입력값을 확인해 주세요')
        // 중복체크
        if (!this.chkOverlabSub(this.subForm.title)) throw new Error('이미 등록된 카테고리입니다')

        // 부모 카테고리
        const parentCate = this.cates[this.selectedCateIndex]
        // 자식 카테고리 리스트에 등록
        this.subCates.push({
          title: this.subForm.title,
          isEdit: false,
          isChecked: false
        })
        // '|' 로 연결된 문자열을 서브 카테고리로 부모에 등록한다.
        const subTitles = this.subCates.map(s => s.title).join('|')
        parentCate.sub = subTitles

        // 부모의 정보를 바탕으로 부모를 업데이트 한다
        const { data } = await this.$axios.post('admin/cate/cateUpdate', parentCate)
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
        // refresh
        await this.getDataFromApi()

        this.init() // 초기화 하고
        this.viewSub = true // 하위 카테고리 보이게한다
        this.goToInput() // 등록폼으로 스크롤을 내린다.

        // !! 백엔드 등록
      } catch (e) {
        this.sbpop(e)
      }
    },
    async setSubEdit (cate, index) {
      if (this.subEdit.title) { // 기존 선택한게 있으면 초기화
        this.subCates[this.subOldIndex].isEdit = false
      }

      this.subOldIndex = index
      cate.isEdit = true
      this.subEdit.title = cate.title
      this.subEdit.isEdit = true // !! 편집중으로 표시
      this.subEdit.isChecked = cate.isChecked

      this.subDndEnabled = false // 선택중엔 드래그앤 드랍 금지
    },
    // 자식 카테고리 업데이트
    async editSubCate (cate, index) {
      try {
        // 입력값 비어있는지 체크
        if (!this.subEdit.title) throw new Error('입력값을 확인해 주세요')
        // 중복체크
        if (!this.chkOverlabSub(this.subEdit.title)) throw new Error('이미 등록된 카테고리입니다')

        // 순서상 변경전값과 변경될값은 여기서 따로 저장한다
        const oldValue = this.subCates[index].title // 변경전 값
        const newValue = this.subEdit.title // 변경될 값

        // 안해도 되는것 같지만 일단 바뀐걸 보여주기위해.. 타이틀을 변경한다.
        this.subCates[index].title = this.subEdit.title

        // 부모 카테고리 객체를 따로 패칭
        const parentCate = this.cates[this.selectedCateIndex]
        // 하위카테고리 수정임을 확실히 보낸다.
        parentCate.changeType = 2
        // 선택한 부모의 sub에 변경될 값을 매칭한다!
        parentCate.sub = this.subCates.map(s => s.title).join('|')
        // 변경되기전의 값과 변경될 값을 보내기위해 붙인다.
        parentCate.oldValue = oldValue
        parentCate.newValue = newValue

        // 부모의 정보를 바탕으로 부모를 업데이트 한다
        const { data } = await this.$axios.post('admin/cate/cateUpdate', parentCate)
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
        // refresh
        await this.getDataFromApi()

        cate.isEdit = false
        this.subDndEnabled = true // 편집 끝나면 다시 가능하게 한다
        this.init() // 리스트 리프레시
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 자식 리스트 순서를 변경하도록 하는 메서드
    async orderChangeSub () {
      try {
        if (this.subOrderChanged) { // 순서가 변경된 경우만..
          this.subOrderChanged = false // 순서변경 여부 초기화

          // 부모 카테고리
          const parentCate = this.cates[this.selectedCateIndex]
          // 부모의 정보를 바탕으로 부모를 업데이트 한다
          const { data } = await this.$axios.post('admin/cate/cateUpdate', parentCate)
          if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
          // refresh
          await this.getDataFromApi()
        } else {
          throw new Error('카테고리 순서를 먼저 변경해 주세요')
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 자식 중복체크 함수
    chkOverlabSub (value) {
      if (this.subCates || this.subCates.length > 0) {
        const titles = this.subCates.map(s => s.title)
        if (titles.includes(value)) {
          return false
        } else {
          return true
        }
      }
    },
    // 자식의 순서변경 이벤트시
    setOrderChageSub (e) {
      this.subOrderChanged = true
      // !! 순서가 변경되면 현재 선택한 부모의 sub 에 다시 매칭해준다.
      // 선택한 부모의 sub에 등록 > selectedCateIndex
      this.cates[this.selectedCateIndex].sub = this.subCates.map(s => s.title).join('|')
    },
    // 자식 - 전체선택
    allCheckSub () {
      if (this.subCates.length > 0) {
        if (!this.subAllChecked) {
          this.subCates.forEach(c => { c.isChecked = true })
          this.subAllChecked = true
        } else {
          this.subCates.forEach(c => { c.isChecked = false })
          this.subAllChecked = false
        }
      }
    },
    // 중요: 일괄합체 - 자식
    async mergeCateSub () {
      try {
        // 체크된 갯수가 2개 이상이어야 의미가 있다
        if (this.checkedLengthSub() < 2) throw new Error(`합체할 2개 이상의 카테고리를 선택하시기 바랍니다`)

        // this.typeNum - 1 이 1이면 소송 / 2면 자문이다
        const cType = this.typeNum - 1

        // 부모 카테고리
        const parentCate = this.cates[this.selectedCateIndex]
        if (await this.$refs.mergeCate02Child.open('카테고리', { width: 550 }, parentCate, this.selectedSubCates, cType)) {
          // refresh
          this.getDataFromApi()
            .then(({ items }) => {
              if (items && items.length > 0) { // 데이터가 있다면..
                this.cates = items // 데이터를 상위카테고리에 매칭 시키고
                this.$nextTick(function () {
                  this.viewSubCate(this.cates[this.selectedCateIndex], this.selectedCateIndex) // 첫번째 상위카테고리의 하위 카테고리를 만든다.
                })
              }
            })
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 자식 - 체크된 갯수 리턴
    checkedLengthSub () {
      this.selectedSubCates = [] // 초기화
      let cnt = 0
      this.subCates.forEach(c => {
        if (c.isChecked) {
          this.selectedSubCates.push(c) // 선택한 카테고리 배열에 넣어준다.
          cnt++
        }
      })
      return cnt
    },
    // 선택삭제
    // async removeSub (cate, index) {
    //   try {
    //     const msg = `삭제시에는 해당 카테고리와 연계된 상위 정보도 동반삭제되므로 신중을 기하십시오.`
    //     if (await this.$refs.cd0923.open('삭제', msg, { color: 'error', width: 380 })) {
    //       this.subCates.splice(index, 1)
    //       // 부모 카테고리
    //       const parentCate = this.cates[this.selectedCateIndex]
    //       // 선택한 부모의 sub에 등록
    //       parentCate.sub = this.subCates.map(s => s.title).join('|')
    //       // 부모의 정보를 바탕으로 부모를 업데이트 한다
    //       const { data } = await this.$axios.post('admin/cate/cateUpdate', parentCate)
    //       if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
    //       // refresh
    //       await this.getDataFromApi()
    //       this.init()
    //     }
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // },
    // 일괄삭제
    // async allRemoveSub () {
    //   try {
    //     let selected = [] // 선택한 것만
    //     for (let i = 0; i < this.subCates.length; i++) {
    //       if (this.subCates[i].isChecked) {
    //         selected.push(this.subCates[i].title)
    //       }
    //     }
    //     if (selected.length <= 0) throw new Error('삭제할 카테고리를 선택하십시오')
    //     const msg = `삭제시에는 해당 카테고리와 연계된 상위 정보도 동반삭제되므로 신중을 기하십시오.`
    //     if (await this.$refs.cd0923.open('삭제', msg, { color: 'error', width: 380 })) {
    //       // 디비처리
    //       // 선택한 카테고리를 순회하면서 지워준다
    //       // !! 차후 DB에서 삭제하고 리스트를 다시 패칭하면 되므로 아래는 사실 필요없다.
    //       for (let i = 0; i < selected.length; i++) {
    //         // id로 인덱스를 찾고 지운다
    //         const index = this.subCates.findIndex(c => c.title === selected[i])
    //         this.subCates.splice(index, 1)
    //       }
    //       // 부모 카테고리
    //       const parentCate = this.cates[this.selectedCateIndex]
    //       // 선택한 부모의 sub에 등록
    //       parentCate.sub = this.subCates.map(s => s.title).join('|')
    //       // 부모의 정보를 바탕으로 부모를 업데이트 한다
    //       const { data } = await this.$axios.post('admin/cate/cateUpdate', parentCate)
    //       if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
    //       // refresh
    //       await this.getDataFromApi()
    //       this.init()
    //     }
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // },
    // 중요: 기타 -----
    // 등록폼으로 스크롤을 내리는 함수
    goToInput () {
      this.$vuetify.goTo('#subInpArea', { duration: 200, offset: -100, easing: 'linear' })
      this.$refs.txtSubAddCate.focus()
    }
  }
}
</script>
